/* .App {
  display: ;
} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
h3, h2{
  font-size:22px; 
  font-weight: 600;
}
body {
  font-family: 'Fira Sans', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0efef;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 500vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="text"], select {
  width: 100%;
}
.page_sec {
  height: calc(100vh - 58px);
  overflow: auto;
  max-width: 100%;
  width: 100%;
  margin: 0!important;
  padding: 0;
  /* background-color: #f2f5f9; */
}
.row > *{
  margin-right: 0!important;
}
.skeleton_loading {
  animation: shine 1.5s linear infinite;
  background: #eee;
  background: linear-gradient(110deg,#ececec 8%,#f5f5f5 18%,#ececec 33%);
  background-size: 200% 100%;
  border-radius: 5px;
}

@keyframes shine {
  to {
      background-position-x: -200%
  }
}
.skeleton-container {
  position: fixed;
  width: calc(100% - var(--sidebarOpn-width) - 0.5rem - 20px);
  right: 15px;
  top: 83px;
  height: calc(100% - 6rem);
  background: #fff;
  z-index: 999;
  padding: 1.5rem;
  display: none;
}
.loading .skeleton-container {
  display: block;
}
.spinner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.button-container {
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
}

.button {
  padding: 5px 10px;
  background-color: #e0e0e0;
  border: none;
  cursor: pointer;
}

.button:hover {
  background-color: #d0d0d0;
}
.pagination {
  margin-top: 1em;
  display: flex;
  justify-content: right;
}

.pagination button {
  margin: 0 0.5em;
  padding: 0.5em 1em;
  background-color: #1c8edf;
  border: none;
  cursor: pointer;
  width: 10px;
}

.pagination button.active {
  background-color: #1c8edf;
}

.pagination button:disabled {
  cursor: not-allowed;
  width: 10px;
}

.pagination button:focus {
  outline: none;
}
.pubDashboardOffer{
  margin-top: 20px;
  background-color: #fff;
}
.pubDashboardOffer h3{
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
  color: #282c32;
}
.pubDashboardOffer .table-responsive{
  padding: 30px;
    border: none;
    box-shadow: none;
} 
.pubDashboardOffer .table-responsive table{
  border: 1px solid #efefef;
} 
.pubDashboardOffer .table-responsive table thead{
  border: 1px solid #efefef;
} 
.pubDashboardOffer .table-responsive table thead th{
  color: #525252;
    background: #fafafa;
    border: none !important;
    padding: .9rem 1rem;
    position: relative;
  } 
.pubDashboardOffer .table-responsive table td{
  padding: .9rem 1rem;
}
.pubDashboardOffer .table-responsive table td a{
    color: rgb(67 166 236);
}
.pubDashboardOffer .table-responsive table tbody tr:nth-child(even){
    background-color: #fff;
}
.pubDashboardOffer .table-responsive table thead th:before {
  position: absolute;
  height: 40px;
  width: 2px;
  background: #efefef;
  right: 0;
  top: 6px;
  z-index: 9;
  content: '';
}

.pubDashboardOffer .table-responsive table thead th:last-child:before {
  height: 0;
  width: 0;
}
.tooltitle {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

/* Tooltip text */
.tooltitle .tooltiptext {
  visibility: hidden;
  width: 50px;
  background-color: rgb(252, 252, 252);
  color: #000;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltitle:hover .tooltiptext {
  visibility: visible;

}